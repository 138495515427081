<template>
    <v-container fluid>
        <v-form @submit.prevent="getCoffeeHouses('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'coffee_house.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_coffee_house') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'coffee_house.create'}" dark color="indigo">{{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="8">
                                <ValidationProvider ref="coffee_house" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="coffee_house" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-coffee-maker"
                                                  :label="$t('coffee_house_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone"
                                                  :label="$t('phone')" color="primary"
                                                  clearable>{{ phone }}
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="10">
                                <ValidationProvider ref="company" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="company" :items="companyItems"
                                                    :error-messages="errors" :search-input.sync="companySearching"
                                                    item-text="name" item-value="id" prepend-icon="mdi-home-city"
                                                    :loading="loadingCompanies" :disabled="loading"
                                                    :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_company_name')"
                                                    :label="$t('company')" @click:clear="companyItems=[]"
                                                    color="primary" clearable autocomplete="off">
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.address_legal"></span>
                                            </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="coffee_houseItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalCoffeeHouses" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="showMenuCategory(item)" icon>
                                    <v-icon>mdi-folder-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('menu_category')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="showMenu(item)" icon>
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('menu_coffee_house_menu')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="showQr(item)" icon>
                                    <v-icon>mdi-qrcode</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('print_qr_code')}}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="!can(['administrator'])"
                                       @click="showSop(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.deleted" @click="deleteCoffeeHouse(item)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import {mask} from 'vue-the-mask'
    import debounce from "lodash/debounce";

    export default {
        name: "CoffeeHouses",
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                sortBy: "name",
                sortDir: true,
                loading: false,
                loadingCompanies: false,
                coffee_house: null,
                phoneRaw: null,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalCoffeeHouses: 0,
                coffee_houseItems: [],
                company: null,
                companyItems: [],
                companySearching: null,
                active: 1,
                activeItems: [
                    {
                        id: null,
                        name: this.$t('all')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                    {
                        id: 0,
                        name: this.$t('no')
                    }
                ],
                headers: [
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('phone'),
                        align: "left",
                        sortable: true,
                        value: "phone"
                    },
                    {
                        text: this.$t('company'),
                        align: "left",
                        sortable: false,
                        value: "company"
                    },
                    {
                        text: this.$t('active'),
                        align: "center",
                        sortable: true,
                        value: "active",
                        width: 120,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 220,
                    },
                ],
            };
        },
        computed: {
            ...mapGetters(['lang', 'phoneMask', 'itemsPerPage', 'perPageItems']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getCoffeeHouses()
                },
                deep: false
            },
            companySearching: debounce(function (val) {
                if (val && !this.company) {
                    this.getCompanies(val)
                }
            }, 500),
        },
        methods: {
            showSop(item) {
                this.$router.push({
                    name: 'coffee_house.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            showMenuCategory(item) {
                this.$router.push({
                    name: 'menu_category_coffee_house',
                    params: {
                        coffee_house_id: item.id
                    }
                })
            },
            showMenu(item) {
                this.$router.push({
                    name: 'coffee_house_menu_pdf',
                    params: {
                        id: item.id
                    }
                })
            },
            clearCompanies() {
                if (!this.company) {
                    this.companyItems = []
                }
            },
            async getCompanies(str) {
                if (str) {
                    this.loadingCompanies = true
                    let params = {};
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.name = str;
                    }
                    await this.$http
                        .get("admin/company", {
                            params: params,
                        })
                        .then(res => {
                            this.companyItems = res.body.data;
                        })
                        .catch(err => {
                            this.companyItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_companies'))
                        })
                        .finally(end => {
                            this.loadingCompanies = false
                        })
                }
            },
            async getCoffeeHouses(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.coffee_house) {
                    params.coffee_house = this.coffee_house;
                }
                if (this.phone) {
                    params.phone = this.phone;
                }
                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id;
                    } else {
                        params.company = this.company;
                    }
                }
                params.active = this.active
                await this.$http
                    .get("admin/coffee_house", {
                        params: params,
                    })
                    .then(res => {
                        this.coffee_houseItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalCoffeeHouses = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.coffee_houseItems = []
                        this.totalCoffeeHouses = 0
                        this.$toastr.error(this.$t('failed_to_get_list_coffee_houses'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async deleteCoffeeHouse(item) {
                if (confirm(this.$t('delete_coffee_house'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/coffee_house/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('coffee_house_has_been_deleted'))
                            this.getCoffeeHouses()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('coffee_house_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async showQr(item) {
                this.loading = true
                let params = {};
                params.filter = 'search'

                params.type = 'coffee_house';
                params.id = item.id;

                await this.$http
                    .get("admin/gr_create", {
                        params: params,
                    })
                    .then(res => {
                        const url = res.body.data.url;
                        if(url !== null){
                            this.download(url, res.body.data.name)
                        }
                        else{
                            this.$toastr.error(this.$t('file_download_error'))
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_download_error'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            download(url, file_name) {
                fetch(url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = file_name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))
                    );
            },

        }
    }
</script>
